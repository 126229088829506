// @ts-nocheck
import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as Icon } from './SolIcon.svg';

export default function SolIcon({...props}) {
  return (
    <SvgIcon component={Icon} inheritViewBox {...props}/>
  );
}
