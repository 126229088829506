// @ts-nocheck
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import QRCard from './QRCard';
import { Box } from '@mui/material';

const qrStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};


export default function BenefitCard(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box>
    <Card variant="outlined" sx={{display: "flex", flexDirection: "column"}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {props.benefitType}
        </Typography>
        <Typography variant="h6" component="div">
          {props.title}
        </Typography>
        <Typography component = "div" sx={{overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2", /* number of lines to show */
                WebkitBoxOrient: "vertical"}}>
          {props.text}
        </Typography>
      </CardContent>
      <CardActions sx={{flexGrow:1, display:"flex", alignItems: "flex-end"}}>        
          <Button size="small" xs={{flexGrow:1}} onClick={handleOpen}>Detalles</Button>
      </CardActions>
    </Card>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <QRCard {...props} style = {qrStyle}/>
      </Modal>
    </Box>
  );
}