// @ts-nocheck
import firebase from 'firebase/compat/app';
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

//remove comments to test cloud functions locally
//import { connectFunctionsEmulator } from 'firebase/functions';



// Get the Firebase config from the auto generated file.
const firebaseConfig = require('./firebase-config.json').result.sdkConfig;

// Initialize Firebase and Firebase Authentication
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);

//remove comments to test cloud functions locally
//connectFunctionsEmulator(functions, "localhost", 5001);

const fetchNFTsByAddress = httpsCallable(functions, 'fetchNFTsByAddress');

export {
  firebaseApp, db, fetchNFTsByAddress}
