// @ts-nocheck
import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import {Link} from "react-router-dom";

export default function NFTPreviewCard(props) {
  return (
    <Card>
      <CardHeader
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={props.nft.collectionName}
        subheader={props.nft.name}
      />
      <CardActionArea component={Link} to= {!props.disabled ? "/nft-details" : "/dashboard"} state= {{nft: props.nft}}>
      <CardMedia
        component="img"
        height="194"
        image={props.nft.imageUrl}
        alt={props.nft.name}
      />
      </CardActionArea>
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {props.nft.description}
        </Typography>
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions> */}
    </Card>
  );
}
