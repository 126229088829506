// @ts-nocheck
import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import StoreIcon from '@mui/icons-material/Store';

export default function ActionsList(props) {
  return (
    <Box sx={{ width: '100%',  bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        <List>
          {/* <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CompareArrowsIcon />
              </ListItemIcon>
              <ListItemText primary="Transferir a wallet personal" />
            </ListItemButton>
          </ListItem>
          <Divider /> */}
          <ListItem disablePadding>
            <ListItemButton  component = {"a"} href={`https://magiceden.io/item-details/${props.nft.tokenAddress}`} target={"_blank"}>
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Ver NFT en el mercado (MagicEden)"/>
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </Box>
  );
}