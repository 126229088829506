// @ts-nocheck
import {Navigate} from 'react-router-dom'
import ResponsiveAppBar from "./ResponsiveAppBar";
import { Box } from '@mui/material';
import { useWallet } from '@solana/wallet-adapter-react';

export default function PrivateRoute({children}) {
  const { publicKey } = useWallet();

  if(!publicKey){
    return <Navigate to='/login' replace/>
  }
  
  return (
    <Box sx={{
      width: "100%", 
      minHeight: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column"
      }}>
      <ResponsiveAppBar publicKey = {publicKey}/>
      {/* check if email is verified, if it is, do childreen, otherwise, show verification alert. */}
      {children}
    </Box>
  )
}
