// @ts-nocheck
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import NFTCard from './components/NFTCard';
import TechnicalDetails from './components/TechnicalDetails';
// import ActionsList from './components/ActionsList';
import BenefitCard from './components/BenefitCard';
import { Chip, Typography, Stack, Divider, Skeleton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {useState, useEffect} from 'react';
import { collection, getDocs,   query, where} from "firebase/firestore";
import {db } from './firebase';
import ActionsList from './components/ActionsList';

function NftDetails(props) {
  const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'left',
    color: theme.palette.text.secondary
  }));
  let location = useLocation();
  // TODO: if there's no state, redirect to dashboard.
  const nft = location.state.nft
  const [redemptions, setRedemptions] = useState();

  let waitSkeleton = 
  <Grid container alignItems={"center"} spacing={1}>   
    <Grid item md={4} sm={4} xs={12}>
      <Skeleton variant="rectangular" animation="wave" height={50} width = {"100%"} sx ={{margin: "5px 0 5px 0"}}/>
      <Skeleton variant="rectangular" height={90} width = {"100%"} sx ={{margin: "5px 0 5px 0"}}/>
      <Skeleton variant="rectangular" animation="wave" height={50} width = {"100%"} sx ={{margin: "5px 0 5px 0"}}/>
    </Grid>
    <Grid item md={4} sm={4} xs={12}>
      <Skeleton variant="rectangular" height={50} width = {"100%"} sx ={{margin: "5px 0 5px 0"}}/>
      <Skeleton variant="rectangular" animation="wave" height={90} width = {"100%"} sx ={{margin: "5px 0 5px 0"}}/>
      <Skeleton variant="rectangular" height={50} width = {"100%"} sx ={{margin: "5px 0 5px 0"}}/>
    </Grid>
    <Grid item md={4} sm={4} xs={12}>
      <Skeleton variant="rectangular" animation="wave" height={50} width = {"100%"} sx ={{margin: "5px 0 5px 0"}}/>
      <Skeleton variant="rectangular" height={90} width = {"100%"} sx ={{margin: "5px 0 5px 0"}}/>
      <Skeleton variant="rectangular" animation="wave" height={50} width = {"100%"} sx ={{margin: "5px 0 5px 0"}}/>
    </Grid>
  </Grid>;

  useEffect(() => {
    const redemptionsRef = collection(db, 'redemptions');
    const q = query(redemptionsRef, where("collection_id", "==", "test"));
    let r = [];
    if(!redemptions){
      getDocs(q).then((results) => {
        results.forEach((doc) => {
          console.log(doc.data());
          const d = doc.data();
          r.push(
            <Grid item md={4} sm={4} xs={12}>
              <BenefitCard benefitType={d.type} title={d.title} text={d.description}/>
            </Grid>
          );
          // need to delete these two once we have data in prod
         
        });
        setRedemptions(r || []);
      });
    }
    
  },  [redemptions]);
  return (
    <Box sx={{ flexGrow: 1, padding: "10px"}}>
      <Typography variant="h4" component="div" gutterBottom sx={{
            color: '#231909'  
        }}>
            {nft.name}
        </Typography>

      <Grid container spacing={1}  sx={{justifyContent: "center"}}>
        <Grid item xs = {12} sm = {8} md={4}>
          <Stack  spacing={2}>
            <NFTCard nft = {nft}/>
            <Item>
              <Typography variant="h5" component="div" gutterBottom sx={{
              color: '#231909'
            }}>Acciones</Typography>
            <ActionsList nft={nft}/>
            </Item>
          
          </Stack>  
        </Grid>    
        <Grid item xs = {12} md={8}>
          <Stack spacing={1}>
            <Item>
              <Box sx={{padding: "10px"}}>
              <Typography variant="h5" component="div" gutterBottom sx={{
                color: '#231909'
              }}>Beneficios
            </Typography>
            { redemptions ? <Grid container alignItems={"center"} spacing={1}>{redemptions} </Grid> : waitSkeleton }
            </Box>
            </Item>
          <Item>
            <Box sx={{padding: "10px"}}>
              <Typography variant="h5" component="div" gutterBottom sx={{
                color: '#231909'
                }}>Información del NFT
              </Typography>
              <Typography variant="h7" component="div" gutterBottom sx={{
                color: '#231909'
                }}>Rasgos del NFT
              </Typography>
              {nft.traits.map((t) => {
                return (<Chip key = {t.trait_type} label={`${t.trait_type}: ${t.value}`} color = "secondary" variant = "outlined" sx = {{margin: "5px"}}/>)              
              })}
              <Divider variant="middle" sx={{padding:"5px"}}/>
              <Typography variant="h7" component="div" gutterBottom sx={{
                color: '#231909'
                }}>Descripción de la colección
              </Typography>
              {nft.description}
              <Divider variant="middle" sx={{padding:"5px"}}/>       
              <Typography variant="h7" component="div" gutterBottom sx={{
                color: '#231909',
                marginTop: "10px"
                }}>Detalles Técnicos
              </Typography>
              <TechnicalDetails nft = {nft}/>
            </Box>
          </Item>
        </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NftDetails