// @ts-nocheck
// Import FirebaseAuth and firebase.
import * as React from 'react';
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import NFTPreviewCard from './components/NFTPreviewCard';
import Typography from '@mui/material/Typography';
import { fetchNFTsByAddress} from './firebase';
import { Paper, Grid, LinearProgress } from '@mui/material';
import { useWallet } from '@solana/wallet-adapter-react';

function Dashboard() {
      const {publicKey} = useWallet();
      const publicKeyString = publicKey?.toString();
      const [nfts, setNFTs] = useState();
      const collections = ["9Z1RNW7fNEJzkXMEw1JM4HcUqHG7QH5p3i1WvydzU9VR","BxQRTndAH3qvQ4Y2zysS2zYJxEXELEjhvRH7YtuG8kfv"];
      
      useEffect(() => {
            console.log(nfts);
            if (!nfts) {
                  fetchNFTsByAddress({ publicKey: publicKeyString, network: "mainnet" })
                        .then((results) => {
                              let renderedNfts = results.data.filter((nft) => {return collections.includes(nft.collectionAddress);})
                              .map((nft, i) => {
                                    return (
                                                <NFTPreviewCard key = {i} index={i} nft={nft}/> 
                                    )
                              });   
                              setNFTs(renderedNfts);
                        });
            }
      });
      
      return (
            <Box sx={{
                  flexGrow: 1,
                  padding: '20px 20px',
                  marginTop: 0
            }}>
                  <Typography variant="h4" component="div" gutterBottom sx={{
                        color: '#231909'
                  }}>
                        Tu Collección de NFTs
                  </Typography>
                  {((nfts && nfts.length) > 0) ? 
                        <Grid container spacing={2}>
                              {nfts.map((x, i) => <Grid item key = {i} xs= {12} sm = {6} md= {4} lg={3} xl = {3}>{x}</Grid>)}
                        </Grid>
                        :  (nfts ? 
                              <Paper variant="outlined" square>
                                    <Typography>No tiene ningún NFT en su wallet. </Typography>
                              </Paper>
                              : <LinearProgress color = "secondary"/>
                        )
                  }     
            </Box>
      );
}

export default Dashboard