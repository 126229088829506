// @ts-nocheck

import * as React from 'react';
import { Chip, AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, Tooltip, MenuItem, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DoneIcon from "@mui/icons-material/Done";
import { yellow } from '@mui/material/colors';
import './ResponsiveAppBar.css';
import SolIcon from './components/SolIcon';
import { useWallet } from '@solana/wallet-adapter-react';
import {createTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { reduceStr } from './Utils';

const pages = ['Mi Colección', 'Mintea tu NFT', 'Instrucciones'];
const settings = [''];

const theme = createTheme({
  palette: {
      
      primary: {
          main: yellow[700],
      }
  }
});

const ResponsiveAppBar = () => {
  const navigate = useNavigate();
  //let history = useHistory();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const {disconnect, publicKey} = useWallet();
  const publicKeyString = publicKey?.toString();
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (key) => {
    if(key){
      switch(key.target.textContent){
        case("Mi Colección"): {
          navigate("/dashboard");
          break;  
        }
        case("Mintea tu NFT"): {
          navigate("/indios-splash");
          break;  
        }
        case("Instrucciones"): {
          window.open('//support.loyal.biz', '_blank');
          break;  
        }
      }
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();  
    disconnect().then(() => {
      console.log("user was signed out");
    });    
  }

  const handleDisconnect = () => {
    console.log("need to implement disconenct");
  }

  const reducedKey = reduceStr(publicKeyString);

  return (
    <AppBar position="static">
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <Link to = "/"><img alt='loyal logo' className = 'logo' src = './loyal_logo_small.png' /></Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>    
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>
          { publicKey && 
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                  <Chip 
                    theme ={theme}
                    sx = {{paddingLeft: "7px"}}
                    color="primary" 
                    onClick={handleOpenUserMenu} 
                    deleteIcon={<DoneIcon />} 
                    onDelete={handleDisconnect} 
                    icon={<SolIcon />} 
                    label={reducedKey}>  
                  </Chip>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Box sx={{margin: "10px"}}>
                  <Typography>Signed in as:</Typography>
                  <Tooltip title={publicKeyString}>
                    <Typography fontWeight = "600">{reducedKey}</Typography>
                  </Tooltip>
                </Box>
                <Divider sx={{marginBottom: "5px"}}></Divider>
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
                <MenuItem key='Logout' onClick={handleLogout}>
                  <Typography textAlign="center">Disconnect Wallet</Typography>
                </MenuItem>
              </Menu>
            </Box>
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;