// @ts-nocheck
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { WalletMultiButton } from '@solana/wallet-adapter-material-ui';
import { useWallet } from '@solana/wallet-adapter-react';

import { Snackbar, Box, Stack, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {createTheme } from '@mui/material/styles';
import { cyan } from '@mui/material/colors';

import SolIcon from './components/SolIcon';

import '@solana/wallet-adapter-react-ui/styles.css';
import ResponsiveAppBar from './ResponsiveAppBar';

const buttonTheme = createTheme({
    palette: {
        
        primary: {
            main: cyan[100],
        }
    }
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login() {
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const { publicKey } = useWallet();

  useEffect( () => {
    async function fetchData(){
      if(publicKey){
        try {
          navigate('/dashboard');
        } catch (error) {
            setErrorMessage(`Signing failed: ${error?.message}`);
            setOpenSnackbar(true);
        }
        
      }
    }
    fetchData();    
  }, [publicKey, navigate]);

  return (
    <Box sx = {{width: "100%"}}>
      <ResponsiveAppBar/>
      <Box sx = {{
        width: "100%",
        backgroundColor: "#ffffe0",
        background: "radial-gradient(circle, #ffffe0, #fff)",
        backgroundImage: "url('./sheet2.png')",
        animation: "bgScroll 20s linear infinite",
        "@keyframes bgScroll": {
          "0%":{
            backgroundPosition : "0px 0px"
          },
          "100%": {
            backgroundPosition : "808px -808px"
          }
        },
        minHeight: "100%",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}>
        <Stack sx ={{marginTop: "-120px"}}>
          <img alt = "Indios de Mayaguez Logo" src="./indios-loyal.png"/>
          <WalletMultiButton theme = {buttonTheme} startIcon={<SolIcon />} color = "primary" variant = "contained" sx={{borderRadius: "0", marginTop: "10px", marginBottom: "10px", height: "48px"}}>  Connecta tu Wallet</WalletMultiButton>
          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Typography sx = {{width: "60%", textAlign: "center", fontSize: "10pt", textShadow: "1px 1px 5px #999"}}>
            En este portal podrás redimir tus boletos y descuentos con tu NFT como parte de la oferta promocional de Los Indios de Mayagüez. Conecta tu wallet de Solana para entrar. Visita este enlace para más detalles e instrucciones.
          </Typography>
      </Box>
    </Box>
  );
}

export default Login