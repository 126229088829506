// @ts-nocheck
import * as React from 'react';
import { Box } from '@mui/material';
import { useSpring, a } from '@react-spring/web'
import { useState } from 'react';
import QRCard from './QRCard';

export default function NFTCard(props) {
  const [flipped, set] = useState(false);

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const qrStyle = {
    bgcolor: 'background.paper',
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  const nftImg = props.nft.imageUrl;

  return (

    <Box onClick={() => set(state => !state)} sx = {{
      position: "relative"      
    }}>
      <a.div
        style={{opacity: opacity.to(o => 1 - o), transform }}
      >
        <img src={props.nft.imageUrl}  alt={props.nft.name} style = {{
          maxWidth: "100%", 
          objectFit: "contain", 
          borderRadius: "10px",
        }}/>

      </a.div>
      <a.div
        style={{
          opacity,
          transform,
          rotateY: '180deg',
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
        }}
      >
      <a.div
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          backgroundImage: `url(${nftImg})`,
          backgroundSize: "cover",
          WebkitBackgroundSize: "cover",
          transform: "scaleX(-1)",
          opacity: 0.25,
          filter: "blur(5px)",
          WebkitFilter: "blur(5px)",
        }}
      ></a.div>
        <QRCard style = {qrStyle} title = "Verificación de NFT" text = "Muestre este QR code para verificar que este NFT es de su propiedad." />
      </a.div>
    </Box>
  );
}
