// @ts-nocheck
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { reduceStr } from '../Utils';

export default function TechnicalDetails(props) {
  console.log(props.nft);
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AlternateEmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Dirección del 'token'" secondary={
          <a href = {`https://solscan.io/token/${props.nft.tokenAddress}`} target = "_blank" rel="noreferrer">{reduceStr(props.nft.tokenAddress)}</a>
          } />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AlternateEmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Dirección de la collección" secondary={
          <a href = {`https://solscan.io/account/${props.nft.collectionAddress}`} target = "_blank"  rel="noreferrer">{reduceStr(props.nft.collectionAddress)}</a>
          } />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AlternateEmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Direcciones de autores de la colección" secondary={
          props.nft.creators.map((d => {return (<span key = {d.address}><a href = {`https://solscan.io/account/${d.address}`}  rel="noreferrer" target = "_blank">{reduceStr(d.address)}</a><br/></span>)}))
        }
          />
      </ListItem>
    </List>
  );
}