// Import FirebaseAuth and firebase.
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react';
import * as anchor from '@project-serum/anchor';
import Home from './mint2/Home';
import ResponsiveAppBar from './ResponsiveAppBar';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import CasinoIcon from '@mui/icons-material/Casino';
import StarIcon from '@mui/icons-material/Star';

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const txTimeoutInMilliseconds = 30000;


export default function IndiosSplash() {
  return (
    <Box sx = {{
      width: "100%", 
      backgroundImage: "url('./sheet2.png')",
      animation: "bgScroll 20s linear infinite",
      "@keyframes bgScroll": {
        "0%":{
          backgroundPosition : "0px 0px"
        },
        "100%": {
          backgroundPosition : "808px -808px"
        }
      }
    }}>
      <ResponsiveAppBar/>
      <Stack sx={{paddingBottom:"25px"}}>
        <Grid container  sx={{margin: "25px 0px"}}>
          <Grid item xs={12} md={6} sx={{display: "flex", verticalAlign: "middle", justifyContent: "center", padding: "0 25px"}}>
            <Box sx={{backgroundImage: "url('./card-wrapper.png')", backgroundSize: "cover", width: "100%", height: {xs: "225px", sm: "350px", md: "275px", lg: "300px"}, borderBottom: "1px solid #555"}}/>
          </Grid>
          <Grid item xs={12} md={6} lg = {5} sx={{paddingTop: "25px"}} >
            <Typography variant="h3" sx={{fontSize: {xs: "2em", md: "2.85em", lg: "3.2em", xl: "4em"},  padding: "0 30px", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
              Únete a #LaTribu
            </Typography>
            <Typography variant="h5" sx={{fontSize: {xs: "1.2em", md: "1.2em", lg: "1.5em", xl: "1.75em"}, padding: "0 30px", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left", fontWeight: "600"}}>
              y se parte de la experiencia VIP
            </Typography>
            <Typography sx={{padding: "0 30px", marginTop: "10px", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left", fontWeight: "100"}}>
            Los Indios de Mayagüez del Baloncesto Superior Nacional en esta temporada conmemoramos los diez años del primer campeonato 
            de la ciudad y para celebrarnos creamos una colección de 50 NFTs, convirtiéndonos en los pioneros de la liga del BSN. 
            </Typography>
            <Box sx={{display: "flex", justifyContent: "center", marginTop: "25px", flexDirection: "column"}}>
              <Home
                candyMachineId={candyMachineId}
                connection={connection}
                txTimeout={txTimeoutInMilliseconds}
                rpcHost={rpcHost}
            />
              <a href='//support.loyal.biz' target = "_blank" rel="noreferrer" style={{alignSelf: "center"}}>Más Información</a>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{backgroundImage: "url('./indios-bg-players.png')", padding: "25px"}}>
          <Typography variant = 'h4' sx={{color: "white", fontWeight: "600", textAlign: "center"}}>COLECCIÓN "CAMPEONES 2012"</Typography>
          <Typography variant = 'h4' sx={{color: "white", fontWeight: "100", textAlign: "center", marginTop: "5px"}}>50 NFTs</Typography>
          <Typography variant = 'h5' sx={{color: "white", fontWeight: "100", textAlign: "center", marginTop: "5px"}}>Precio de venta: 2 SOL </Typography>
        </Box>
        <Box sx={{backgroundImage: "url('./newspaper.png')", backgroundSize: "cover", padding: "10px"}}>
          <Typography variant = 'h4' sx={{textAlign: "center", margin: "10px 20px 25px 20px"}}>BENEFICIOS DEL NFT</Typography>
          <Grid container sx={{marginTop: "10px", padding: "0 25px"}}>
          <Grid item xs={12} sm={6} lg={3} sx = {{marginTop: "10px", height: "225px",  padding: "20px"}}>
            <Box sx = {{backgroundImage: "url('./ticket-bg.png')", backgroundSize: "cover", height: "100%"}}>
              <ListItem sx = {{height: "100%", transform: "rotate(-0.05turn)"}}>
                  <ListItemText
                    primary="10% De Descuento En Mercancía"
                    primaryTypographyProps={{variant: "h5", sx:{fontWeight: "bold", margin: "-25px 0 0 100px"}}}
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} sx = {{marginTop: "10px", height: "225px",  padding: "20px"}}>
            <Box sx = {{backgroundImage: "url('./ticket-bg.png')", backgroundSize: "cover", height: "100%"}}>
              <ListItem sx = {{height: "100%", transform: "rotate(-0.05turn)"}}>
                  <ListItemText
                    primary="Rifa de mercancía 2012"
                    primaryTypographyProps={{variant: "h5", sx:{fontWeight: "bold", margin: "-25px 0 0 100px"}}}
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} sx = {{marginTop: "10px", height: "225px",  padding: "20px"}}>
            <Box sx = {{backgroundImage: "url('./ticket-bg.png')", backgroundSize: "cover", height: "100%"}}>
              <ListItem sx = {{height: "100%", transform: "rotate(-0.05turn)"}}>
                  <ListItemText
                    primary="Dos Boletos en Arena General"
                    primaryTypographyProps={{variant: "h5", sx:{fontWeight: "bold", margin: "-25px 0 0 100px"}}}
                  />
                </ListItem>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} sx = {{marginTop: "10px", height: "225px",  padding: "20px"}}>
              <Box sx = {{backgroundImage: "url('./ticket-bg.png')", backgroundSize: "cover", height: "100%"}}>
                <ListItem sx = {{height: "100%", transform: "rotate(-0.05turn)"}}>
                  <ListItemText
                    primary="Acceso exclusivo a pre-ventas"
                    primaryTypographyProps={{variant: "h5", sx:{fontWeight: "bold", margin: "-25px 0 0 100px"}}}
                  />
                </ListItem>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Typography variant = 'h4' sx={{textAlign: "center", margin: "50px 20px 0 20px"}}>¿AÚN TIENES PREGUNTAS? ¡TE LAS RESPONDEMOS!</Typography>
        <Grid container sx={{marginTop: "10px", padding: "0 25px"}}>
          <Grid item xs={12} md={6} sx = {{paddingRight: {sm: "10px", md: "40px"}, marginTop: "25px"}}>
            <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
              ¿QUÉ ES UN NFT?
            </Typography>
            <Typography sx={{display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
              Es un token no fungible; es la manera de representar que eres dueño de un asset digital. Muchas personas lo confunden pensando que NFT es el arte que se compra o crea, pues no es así, el NFT es el “recibo” de la compra de tu token digital.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx = {{paddingRight: {sm: "10px", md: "40px"}, marginTop: "25px"}}>
            <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
              ¿CUÁNTO CUESTA UN NFT DE LA TRIBU?
            </Typography>
            <Typography sx={{ display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
            Cada NFT cuesta 2 Solana. Debe adquirir las dos monedas accesando a Coinbase.com para poder completar su compra. Recuerda, debes primero haber creado tu wallet en Phantom. 
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={6} sx = {{paddingRight: {sm: "10px", md: "40px"}, marginTop: "25px"}}>
            <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
              ¿DE CUÁNTOS NFTs ES LA COLECCIÓN DE LA TRIBU?
            </Typography>
            <Typography sx={{display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
              Creamos 50 únicos NFTs como parte de nuestra primera colección. 
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} sx = {{paddingRight: {sm: "10px", md: "40px"}, marginTop: "25px"}}>
            <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
              ¿BAJO CUÁL BLOCKCHAIN ESTÁ LA COLECCIÓN?
            </Typography>
            <Typography sx={{display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
              La colección está siendo custodiada por el blockchain de Solana (SOL).
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx = {{paddingRight: {sm: "10px", md: "40px"}, marginTop: "25px"}}>
            <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
            ¿CUÁLES SON LA UTILIDADES?
            </Typography>
            <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <StoreMallDirectoryIcon color="error" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="10% De Descuento En Mercancía"
                    primaryTypographyProps={{sx:{fontWeight: "bold"}}}
                    secondaryTypographyProps={{variant: "subtitle1"}}
                    secondary={'Adquiere la mercancía de los Indios de Mayagüez en la página web: hoodies, tumbler cup, jersey, t-shirts y otros. '}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CasinoIcon color="error"  />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Rifa de mercancía 2012"
                    primaryTypographyProps={{sx:{fontWeight: "bold"}}}
                    secondaryTypographyProps={{variant: "subtitle1"}}
                    secondary={'La oportunidad de obtener una Jersey Retro del 2012 de uno de los siguientes jugadores: Donta Smith, Andres “Corky” Ortiz, Carlos Rivera o Ramon Clemente.'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <LocalActivityIcon color="error" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Dos Boletos en Arena General"
                    primaryTypographyProps={{sx:{fontWeight: "bold"}}}
                    secondaryTypographyProps={{variant: "subtitle1"}}
                    secondary={'Redime dos boletos en Área General para un juego local de la temporada.'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <StarIcon color="error" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{sx:{fontWeight: "bold"}}}
                    secondaryTypographyProps={{variant: "subtitle1"}}
                    primary="Acceso a 'Whitelist'"
                    secondary={'Acceso a la 2da colección de NTFs de los Indios.'}
                  />
                </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6} sx = {{paddingRight: {sm: "10px", md: "40px"}, marginTop: "25px"}}>
          <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
              ¿QUÉ ES EL BLOCKCHAIN?
            </Typography>
            <Typography sx={{display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
              El blockchain es un sistema de registros descentralizado, el cuál fue popularizado con Bitcoin y las crypto monedas. Este sistema permite ventas y transferencias de NFTs entre personas sin necesitar una entidad facilitadora como por ejemplo, eBay. No
              existe una autoridad central que pueda controlar las transacciones que ocurren.
            </Typography>
            <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left", marginTop: "25px"}}>
              ¿QUÉ ES UN WALLET?
            </Typography>
            <Typography sx={{display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
              Es una billetera, normal como la que usas diariamente para guardar tu dinero y/o documentos. En el mundo crypto, un wallet es donde podrás guardar tus NFTs. Existen dos tipos de wallet: la que vive en el software, o sea en tu computadora y la que vive en un hardware, aparte de tu computadora. 
            </Typography>
            <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left", marginTop: "25px"}}>
              ¿QUÉ ES MINTEAR?
            </Typography>
            <Typography sx={{display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
            Esa palabra anglosajona: mintear, viene de "MINT" o de "acuñar" en español, por ejemplo: 
            "los casinos son los responsables de acuñar sus respectivas fichas" o sea de crear/hacer. 
            En relación a todo esto, sería: "yo acuño un NFT, yo mintié un NFT.".
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx = {{paddingRight: {sm: "10px", md: "40px"}, marginTop: "25px"}}>
            <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
              ¿QUÉ SON LOS 'GAS FEES'?
            </Typography>
            <Typography sx={{display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
              Es la tarifa que nosotros los usuarios debemos pagar a los mineros por la energía que gastan en el 
            momento que se realiza la acción de mintear, crear o vender un NFT dentro del blockhain. 
            </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx = {{paddingRight: {sm: "10px", md: "40px"}, marginTop: "25px"}}>
            <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
              ¿PUEDO RE-VENDER MI NFT? 
            </Typography>
            <Typography sx={{display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
              Por supuesto, una vez esté minteado, lo puedes revender por el precio que desees. 
            </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx = {{paddingRight: {sm: "10px", md: "40px"}, marginTop: "25px"}}>
          <Typography variant="h6" sx={{fontWeight: "bold", display: "flex", verticalAlign: "middle", justifyContent: "left", textAlign: "left"}}>
            ¿QUE ES MAGIC EDEN  ? 
          </Typography>
          <Typography sx={{display: "flex", verticalAlign: "middle", justifyContent: "left", fontWeight: "100", textAlign: "left"}}>
          Es la plataforma o tienda virtual hecha para la red Solana. Dentro de esta puedes comprar, vender y acuñar (mintear) los NFT. Debes crear una cuenta para poder tener visibilidad de tus NFTs, venderlos y recibir ofertas. 
          </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
