// @ts-nocheck

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

//MUI
import Box from '@mui/material/Box';

// Styles
import './firebaseui-styling.global.css'; // Import globally.

import Login from "./Login";
import Dashboard from "./Dashboard";
import NftDetails from "./NftDetails";

import PrivateRoute from './PrivateRoute'
// import Register from './Register';

import SolContext from "./components/SolContext";
import Mint from './mint2/Mint';
import IndiosSplash from './IndiosSplash';
import Tutorial from './Tutorial';

import LogRocket from 'logrocket';

LogRocket.init('zydeor/loyal');


/**
 * The Splash Page containing the login UI.
 */
function App()  {

    return(
      
      <SolContext>
        <Box sx={{
          height: "100%",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1
        }}>
          <Box maxWidth="100%" sx={{
            width: "100%",
            minHeight: "100%",
            marginTop: 0,
            flex: 1,
            display: "flex",
          }}>
              <Router>
                  <Routes>
                    <Route exact path='/dashboard' element={
                      <PrivateRoute>
                        <Dashboard/>
                      </PrivateRoute>
                    }/>

                    <Route exact path='/' element={
                      <IndiosSplash/>
                    }/>

                    <Route path="/nft-details" element={
                      <PrivateRoute>
                        <NftDetails />
                      </PrivateRoute>
                    } />

                    <Route path="/login" element={<Login/>} />

                    <Route path="/nft-details" element={<NftDetails />} />

                    <Route path="/indios-splash" element={<IndiosSplash />} />
                    
                    <Route path="/mint" element={<Mint />} />

                    <Route path="/tutorial" element={<Tutorial />} />
                  </Routes>
                </Router>
          </Box>
        </Box>
      </SolContext>
    )
  }

export default App;