// @ts-nocheck
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { QRCode } from 'react-qrcode-logo';

export default function QRCard(props){
  
  return (
    <Box sx={props.style} >
      <Typography id="modal-modal-title" variant="h4" component="h2" >
        {props.title}
      </Typography>
      <QRCode eyeRadius={[
        [10, 10, 10, 10], // top/right eye
        [10, 10, 10, 10], // bottom/left
        [10, 10, 10, 10], // bottom/right
  ]} logoImage = "./loyal_logo_small_black.png" qrStyle = "dots" removeQrCodeBehindLogo="true" size = "250" value="https://phantom.app/ul/browse/https%3A%2F%2Fdev.loyal.biz%2Fdashboard?ref=https%3A%2F%2Floyal.biz" />
      <Typography id="modal-modal-description">
        {props.text}
      </Typography>
    </Box>
  );
}


