import { Box, Paper, Container, Stack, Typography } from '@mui/material';
import ResponsiveAppBar from './ResponsiveAppBar';

const Tutorial = () => {
  return (
    <Container sx={{ padding: '0 !important' }} maxWidth={false}>
      <Stack
        sx={{ paddingBottom: '25px' }}
        spacing={{ xs: 4, sm: 4, md: 4, lg: 6, xl: 6 }}
      >
        <ResponsiveAppBar />
        <Box sx={{ p: { xs: 1, sm: 2, md: 4, lg: 4, xl: 4 } }}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '1.5em', md: '2.5em', lg: '2.5em', xl: '2.5em' },
              textAlign: 'center',
            }}
          >
            Aprende a descargar phantom wallet y comprar solana (sol)
          </Typography>
        </Box>
        <Box sx={{ px: { xs: 2, sm: 2, md: 6, lg: 8, xl: 8 } }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: '#E7E7E7',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: '1em', md: '1.5em', lg: '2em', xl: '2em' },
              }}
            >
              1. Visita <a href = 'https://phantom.app/'  rel="noreferrer" target="_blank">https://phantom.app/</a>
            </Typography>
          </Paper>
        </Box>
        
        <Box sx={{ px: { xs: 2, sm: 2, md: 6, lg: 8, xl: 8 } }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: '#E7E7E7',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: '1em', md: '1.5em', lg: '2em', xl: '2em' },
              }}
            >
              2. Selecciona "Chrome"
            </Typography>
            <img
              src="./tutorial_assets/3.png"
              alt="step 2"
              style={{ width: '100%', maxWidth: '768px', padding: '16px 0px' }}
            />
          </Paper>
        </Box>
        <Box sx={{ px: { xs: 2, sm: 2, md: 6, lg: 8, xl: 8 } }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: '#E7E7E7',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: '1em', md: '1.5em', lg: '2em', xl: '2em' },
              }}
            >
              3. Selecciona “add to chrome” y “add extension”
            </Typography>
            <img
              src="./tutorial_assets/4.png"
              alt="step 2"
              style={{ width: '100%', maxWidth: '768px', padding: '16px 0px' }}
            />
          </Paper>
        </Box>
        <Box sx={{ px: { xs: 2, sm: 2, md: 6, lg: 8, xl: 8 } }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: '#E7E7E7',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: '1em', md: '1.5em', lg: '2em', xl: '2em' },
              }}
            >
              4. Escoge “create a new wallet”
            </Typography>
            <img
              src="./tutorial_assets/5.png"
              alt="step 2"
              style={{ width: '100%', maxWidth: '768px', padding: '16px 0px' }}
            />
          </Paper>
        </Box>
        <Box sx={{ px: { xs: 2, sm: 2, md: 6, lg: 8, xl: 8 } }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: '#E7E7E7',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: '1em', md: '1.5em', lg: '2em', xl: '2em' },
              }}
            >
              5. Crea tu contraseña (public key)
            </Typography>
            <img
              src="./tutorial_assets/6.png"
              alt="step 2"
              style={{ width: '100%', maxWidth: '768px', padding: '16px 0px' }}
            />
          </Paper>
        </Box>
        <Box sx={{ px: { xs: 2, sm: 2, md: 6, lg: 8, xl: 8 } }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: '#E7E7E7',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: '1em', md: '1.5em', lg: '2em', xl: '2em' },
              }}
            >
              6. De forma segura, guarda tu recovery phrase. *sin estas 12
              palabras no podrás tener acceso a tu wallet en un futuro.*
            </Typography>
            <img
              src="./tutorial_assets/7.png"
              alt="step 2"
              style={{ width: '100%', maxWidth: '768px', padding: '16px 0px' }}
            />
          </Paper>
        </Box>
        <Box sx={{ px: { xs: 2, sm: 2, md: 6, lg: 8, xl: 8 } }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: '#E7E7E7',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: '1em', md: '1.5em', lg: '2em', xl: '2em' },
              }}
            >
              7. ¡LISTO!
            </Typography>
            <img
              src="./tutorial_assets/8.png"
              alt="step 2"
              style={{ width: '100%', maxWidth: '768px', padding: '16px 0px' }}
            />
          </Paper>
        </Box>
        <Box sx={{ px: { xs: 2, sm: 2, md: 6, lg: 8, xl: 8 } }}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              backgroundColor: '#E7E7E7',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: '1em', md: '1.5em', lg: '2em', xl: '2em' },
              }}
            >
              8. Aquí encontrarás la extensión y podrás contectarte con tu
              contraseña (Public Key) y Recovery Phrase
            </Typography>
            <img
              src="./tutorial_assets/9.png"
              alt="step 2"
              style={{ width: '100%', maxWidth: '768px', padding: '16px 0px' }}
            />
          </Paper>
        </Box>
      </Stack>
    </Container>
  );
};

export default Tutorial;
